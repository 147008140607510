import React, { useState } from "react";
import { SubmitCard } from "./API";
import toast from "react-hot-toast";

const CardForm = ({
  errors,
  data,
  location,
  appointment,
  setData,
  setDidSubmit,
  setShowRequire,
  showRequire,
}) => {
  const handleChange = (e) => {
    const target = e.target;
    setData({ ...data, [target.name]: target.value });
  };

  const handleChangeNumber = (maxLength) => (e) => {
    if (e.nativeEvent.data != null && isNaN(parseInt(e.nativeEvent.data))) {
      return;
    }

    const target = e.target;
    if (target.value.length > maxLength) return;

    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowRequire(true);

    if (!Object.values(errors).some(Boolean)) {
      try {
        const response = await SubmitCard(data);
        console.log(response);
      } catch (error) {
        if (
          error.results &&
          error.results.length > 0 &&
          error.results[0].result &&
          error.results[0].result.error
        ) {
          toast.error("❌ Error: " + error.results[0].result.error);
        }

        return console.log(error.results[0].result);
      }

      setDidSubmit(true);
    }
  };

  return (
    <form className="card__details" onSubmit={handleSubmit}>
      <div className="card__details__input">
        <span>
          You are confirming your appointment at <b>{location.name}</b>{" "}
          scheduled for{" "}
          <b>
            {appointment.date} at {appointment.time}
          </b>
          .
        </span>
      </div>

      <div className="card__details__input">
        <label>CARDHOLDER NAME</label>
        <input
          type="text"
          name={"name"}
          onChange={handleChange}
          placeholder="e.g. Rosie Foster"
          value={data["name"]}
          className={errors["name"] ? "error" : ""}
        />
        {errors["name"] && <div className="error">{errors["name"]}</div>}
      </div>
      <div className="card__details__input">
        <label>CARD NUMBER</label>
        <input
          type="text"
          name={"number"}
          onChange={handleChangeNumber(16)}
          placeholder="e.g. 1234 5678 9123 0000"
          value={data["number"]}
          className={errors["number"] ? "error" : ""}
        />
        {errors["number"] && <div className="error">{errors["number"]}</div>}
      </div>

      <div className="card__details__input">
        <label>EXP. DATE (MM/YY)</label>
        <div className="expiry">
          <input
            type="text"
            name="expM"
            onChange={handleChangeNumber(2)}
            placeholder="MM"
            value={data["expM"]}
            className={errors["expM"] ? "error" : ""}
          />
          <input
            type="text"
            name="expY"
            onChange={handleChangeNumber(2)}
            placeholder="YY"
            value={data["expY"]}
            className={errors["expY"] ? "error" : ""}
          />
        </div>
        {(errors["expM"] || errors["expY"]) && (
          <div className="error">{errors["expM"] || errors.expY}</div>
        )}
      </div>
      <div className="card__details__input">
        <label>CVC AND ZIP</label>
        <div className="expiry">
          <input
            type="text"
            name="cvc"
            onChange={handleChangeNumber(3)}
            placeholder="Card CVC e.g. 321"
            value={data["cvc"]}
            className={errors["cvc"] ? "error" : ""}
          />
          <input
            type="text"
            name="address_postal_code"
            onChange={handleChangeNumber(10)}
            placeholder="Zip Code e.g. 90210"
            value={data["address_postal_code"]}
            className={errors["address_postal_code"] ? "error" : ""}
          />
        </div>
        {(errors["cvc"] || errors["address_postal_code"]) && (
          <div className="error">
            {errors["cvc"] || errors["address_postal_code"]}
          </div>
        )}
      </div>

      <button className="cta">Confirm</button>
    </form>
  );
};

export default CardForm;
