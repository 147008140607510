import { SubmitCode } from "./API";

export const getCode = async () => {
  const url = new URL(window.location.href);
  let code = "invalid";
  const pathSegments = url.pathname.split("/");
  if (pathSegments.length > 0) {
    const codeFromPath = pathSegments[pathSegments.length - 1].substring(0, 10);

    if (!codeFromPath || codeFromPath === "index.html") {
      const urlParams = new URLSearchParams(window.location.search);
      const codeFromQuery = urlParams.get("code");
      if (codeFromQuery != null) {
        code = codeFromQuery.substring(0, 10);
      }
    } else {
      code = codeFromPath;
    }
  }
  
  const formatCheck = !isNaN(parseInt(code));

  if (!formatCheck) {
    return {
      code: null,
      reservationData: null,
      isValid: false,
    };
  }

  const { reservationData, isValid } = await verifyCode(code);

  return {
    code,
    reservationData: reservationData,
    isValid,
  };
};

export const verifyCode = async (code) => {
  const JSONresponse = await SubmitCode(code);
  
  if (!JSONresponse.results || JSONresponse.results.length != 1 || !JSONresponse.results[0].result) {
    return { isValid: false };
  }

  return { isValid: true, reservationData: JSONresponse.results[0].result };
};

export const parseTime = (dt) => {
  const datetime = new Date(dt);
  const time = datetime.toTimeString();
  const date = datetime.toDateString();

  return { time, date };
};
