export const SubmitCard = async (data) => {
  const response = await fetch(
    "https://blvdgateway.callrosie.ai",
    {
      method: "POST",
      headers: {
        "x-vapi-secret": "asd",
      },
      body: JSON.stringify({
        message: {
          call: { id: "0" },
          functionCall: {
            name: "completeAppointment",
            parameters: {
              code: data.code,
              card: {
                card: {
                  name: data.name,
                  number: data.number,
                  cvv: data.cvc,
                  exp_month: data.expM,
                  exp_year: data.expY,
                  address_postal_code: data.address_postal_code,
                },
              },
            },
          },
        },
      }),
    }
  );

  if (response.status != 200) {
    throw await response.json();
  }

  return response.json();
};

export const SubmitCode = async (code) => {
  const response = await fetch(
    "https://blvdgateway.callrosie.ai",
    {
      method: "POST",
      headers: {
        "x-vapi-secret": "asd",
      },
      body: JSON.stringify({
        message: {
          call: { id: "0" },
          functionCall: {
            name: "getPaymentReservation",
            parameters: {
              code,
            },
          },
        },
      }),
    }
  );

  if (response.status != 200) {
    throw await response.json();
  }

  return await response.json();
};
