import React from "react";
import { createRoot } from "react-dom/client";

import toast, { Toaster } from "react-hot-toast";

import { useState, useDeferredValue, useCallback, useEffect } from "react";
import CodeForm from "./CodeForm";
import CardForm from "./CardForm";
import { getCode } from "./CodeVerifier";

const defaultValues = {
  name: "",
  number: "",
  expM: "",
  expY: "",
  cvc: "",
  address_postal_code: "",
  code: "",
};

const numbers = ["!", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const alphabets = ["!"];
const alphanumerics = [...numbers];

for (let i = 65; i < 91; i++) {
  alphabets.push(String.fromCharCode(i));
  alphanumerics.push(String.fromCharCode(i));
}

function App() {
  const [data, setData] = useState(defaultValues);
  const [location, setLocation] = useState({ name: "Undefined" });
  const [codeEntered, setCodeEntered] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialCodeInvalid, setInitialCodeInvalid] = useState(false);
  const [appointment, setAppointment] = useState({
    date: "Undefined",
    time: "Undefined",
  });
  const [didSubmit, setDidSubmit] = useState(false);
  const [showRequire, setShowRequire] = useState(false);
  const { errors } = useError(data, showRequire);

  useEffect(async () => {
    const { code, reservationData, isValid } = await getCode();

    if (isValid) {
      setData((prevData) => ({ ...prevData, code }));
      const datetime = new Date(reservationData.time);
      const time = datetime.toTimeString();
      const date = datetime.toDateString();

      setAppointment({ date, time });
      setLocation({ name: reservationData.location });
      setCodeEntered(true);
    }
    if (code && !isValid) {
      setInitialCodeInvalid(true);
      toast.error("❌ This code you have entered is invalid.");
    }

    setInitialLoading(false);
  }, []);

  return (
    <main className="main">
      <div className="left">
        <Front data={data} />
        <Back cvc={data.cvc} />
      </div>
      <div className="right">
        {initialLoading ? (
          <div className="loaderContainer">
            <div class="loader"></div>
            Loading
          </div>
        ) : codeEntered ? (
          didSubmit ? ( // Show success page as we submitted the card data
            <div className="success">
              <h2>Appointment confirmed with {location.name}!</h2>
              <p>We've added your card details.</p>
            </div>
          ) : (
            <CardForm // Display card form
              errors={errors}
              data={data}
              location={location}
              appointment={appointment}
              setData={setData}
              setDidSubmit={setDidSubmit}
              setShowRequire={setShowRequire}
              showRequire={showRequire}
            />
          )
        ) : (
          <CodeForm // No code in url display the code form
            setLocation={setLocation}
            setAppointment={setAppointment}
            setCodeEntered={setCodeEntered}
            setData={setData}
            initialCodeInvalid={initialCodeInvalid}
          />
        )}
      </div>
      <Toaster
        toastOptions={{
          className: "",
          success: {
            style: {
              border: "1px solid #FAFAFA",
              padding: "16px",
              color: "#302b63",
              fontSize: "0.9em",
              borderRadius: "5px",
              background: "#fefefe",
            },
          },
          error: {
            style: {
              border: "3px solid red",
              padding: "16px",
              color: "#302b63",
              fontSize: "0.9em",
              borderRadius: "5px",
              background: "#fefefe",
            },
          },
        }}
      />
    </main>
  );
}

const AlphaNumeric = ({ length, value, defaultValue = "0000", chars = [] }) => {
  const _value = (value || "") + defaultValue.slice(value?.length);
  const isActive = (ind, _ind) => {
    if (chars.indexOf(_value[ind]?.toUpperCase()) === -1 && _ind === 0)
      return true;
    return chars.indexOf(_value[ind]?.toUpperCase()) === _ind;
  };

  const className = (ind, i) =>
    isActive(ind, i) && i === 0
      ? "active error"
      : isActive(ind, i)
      ? "active"
      : "";

  return (
    <div className="alphanumeric">
      {Array(length)
        .fill(0)
        .map((_, ind) => {
          return (
            <ul key={ind}>
              {chars.map((char, i) => (
                <li key={i} className={className(ind, i)}>
                  {char}
                </li>
              ))}
            </ul>
          );
        })}
    </div>
  );
};

const Back = ({ cvc }) => {
  return (
    <div className="card card-back">
      <div className="cvv-container">
        <div className="cvv">
          <AlphaNumeric
            length={3}
            value={cvc}
            defaultValue="000"
            chars={alphanumerics}
          />
        </div>
      </div>
    </div>
  );
};

const Front = ({ data }) => {
  const { name, number, expM, expY } = data;

  const getNumber = () => {
    const splitNumber = number
      .split("")
      .reduce((acc, curr, idx) => {
        if (idx % 4 === 0 && acc.length > 3) {
          acc.push(" ");
        }

        acc.push(curr);
        return acc;
      }, [])
      .join("");

    const sections = splitNumber.split(" ");
    const remaining = new Array(4 - sections.length).fill(0);
    return [...sections, ...remaining].map((item, i) => (
      <AlphaNumeric length={4} value={item} chars={numbers} key={i} />
    ));
  };

  const getName = () => {
    let names = name.split(" ");
    if (!names.join("")) names = ["Jane", "Appleseed"];
    const firstName = names[0];
    const middleName = names[1];
    const lastName = names[2];
    return (
      <>
        <AlphaNumeric
          length={firstName?.length}
          value={firstName}
          defaultValue={firstName}
          chars={alphabets}
        />
        <AlphaNumeric
          length={middleName ? middleName?.length : 0}
          value={middleName}
          defaultValue={middleName}
          chars={alphabets}
        />
        <AlphaNumeric
          length={lastName ? lastName?.length : 0}
          value={lastName}
          defaultValue={lastName}
          chars={alphabets}
        />
      </>
    );
  };

  const getDate = () => (
    <>
      <AlphaNumeric length={2} value={expM} defaultValue="00" chars={numbers} />
      /
      <AlphaNumeric length={2} value={expY} defaultValue="00" chars={numbers} />
    </>
  );

  return (
    <div className="card-front card">
      <div className="card__number">{getNumber()}</div>
      <div className="card__footer">
        <div className="card__holderName">{getName()}</div>
        <div className="card__expiry">{getDate()}</div>
      </div>
    </div>
  );
};

const useError = (data, showRequire) => {
  const deferredData = useDeferredValue(data);
  const [errors, setErrors] = useState({});
  const setError = (name, error) =>
    setErrors((errors) => ({ ...errors, [name]: error }));

  const checkForError = useCallback(() => {
    if (
      deferredData.cvc?.match(/\D/) ||
      (deferredData.cvc && deferredData.cvc?.length !== 3)
    )
      setError("cvc", "Invalid CVV");
    else setError("cvc", "");
    if (deferredData.name?.match(/[^a-zA-Z\s]/))
      setError("name", "Invalid Name");
    else if (deferredData.name?.length > 20) setError("name", "Too long");
    else setError("name", "");
    if (deferredData.number?.match(/[^\d\s]/))
      setError("number", "Wrong format, numbers only");
    else if (deferredData.number && deferredData.number?.length !== 16)
      setError("number", "Invalid card number");
    else setError("number", "");
    if (deferredData.expM?.match(/\D/) || deferredData.expM > 12)
      setError("expM", "Invalid Month");
    else if (deferredData.expM && (deferredData.expM + "").length !== 2)
      setError("expM", "Invalid format");
    else setError("expM", "");
    if (
      deferredData.expY &&
      deferredData.expY < +`${new Date().getFullYear()}`.slice(2)
    )
      setError("expY", "Expired Card");
    else if (deferredData.expY && (deferredData.expY + "").length !== 2)
      setError("expM", "Invalid format");
    else if (
      deferredData.expY?.match(/\D/) ||
      deferredData.expY > +`${new Date().getFullYear()}`.slice(2) + 10
    )
      setError("expY", "Invalid Year");
    else setError("expY", "");
    if (showRequire) {
      Object.keys(data).forEach((item) => {
        if (!data[item]) setError(item, "Cant be empty");
      });
    }
  }, [data, showRequire, deferredData]);

  useEffect(() => {
    checkForError();
  }, [checkForError]);
  return { errors };
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
