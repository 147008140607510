import React, { useState } from "react";
import { verifyCode, parseTime } from "./CodeVerifier";
import toast from "react-hot-toast";

const CodeForm = ({
  setLocation,
  setAppointment,
  setCodeEntered,
  setData,
  initialCodeInvalid,
}) => {
  const [code, setCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const verificationResult = await verifyCode(code);

    if (!verificationResult.isValid) {
      return toast.error("❌ This code you have entered is invalid.");
    }
    setData((prevData) => ({ ...prevData, code }));
    setAppointment(parseTime(verificationResult.reservationData.time));
    setLocation({ name: verificationResult.reservationData.location });
    setCodeEntered(true);
  };
  console.log(initialCodeInvalid);

  return (
    <form className="card__details" onSubmit={handleSubmit}>
      {initialCodeInvalid ? (
        <div className="card__details__input">
          The code you have provided is invalid, please enter a new one
        </div>
      ) : (
        ""
      )}
      <div className="card__details__input">
        <label>Verification Code</label>
        <input
          type="text"
          name="code"
          onChange={(e) => setCode(e.target.value)}
          placeholder="e.g. 1234"
          value={code}
          className={code ? "" : "error"}
        />
        {!code && <div className="error">Code can't be empty</div>}
      </div>
      <button className="cta">Confirm</button>
    </form>
  );
};

export default CodeForm;
